.boardWrapper {
  width: 100vw;
  min-width: 300px;
  min-height: 300px;
  position: relative;
  padding-right: 12px;
  padding-top: 12px;
  padding-left: 48px;
  padding-bottom: 52px;
  overflow: hidden;
  background: #F4F7FA;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 8px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  font-size: 14px;
  color: #34364C;

  @media (max-width: 700px) {
    font-size: 11px;
  }
}

.board {
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cell {
  position: relative;
  transition: all .2s;
}

.cellBlack {
  background-color: #B7C0D8;
}

.cellWhite {
  background-color: #E8EDF9;
}

.availableCell {

  &:hover {
    cursor: pointer;

    .cellCircle {
      opacity: .8;
    }
  }
}

.cellCircle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 500px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  opacity: 0;
  background-color: #7B61FF;

  transition: all .2s;
}

.cellCircleShow {
  opacity: 1;
}

.boardLeft {
  display: flex;
  flex-flow: column-reverse;
  position: absolute;
  width: 48px;
  height: 100%;
  padding-bottom: 52px;
  padding-top: 12px;
  top: 0;
  left: 0;
}

.boardLeftItem {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.boardBottom {
  display: flex;
  flex-flow: row;
  position: absolute;
  height: 52px;
  width: 100%;
  padding-left: 48px;
  padding-right: 12px;
  bottom: 0;
  left: 0;
}

.boardBottomItem {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.cellSelected {
  background-color: #B1A7FC;
}

.gameWon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .7);
  color: white;
  font-size: 20px;
}

.gameWonButton {
  color: inherit;

}