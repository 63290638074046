/* radioButton.css */
.radio-button-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: start; /* Align content to the start of the container */
  cursor: pointer;
  margin-bottom: 8px; /* Add some space between radio buttons */
}

.radio-button-label {
  display: flex;
  align-items: center; /* Align radio and text vertically */
  cursor: pointer;
}

.radio-button-label .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid gray;
  margin-right: 8px;
  transition: background-color 0.3s ease;
  position: relative;
}

.radio-button-label input[type="radio"]:checked + .checkmark {
  background-color: var(--primary-color); /* Change to your desired color */
  border-color: var(--primary-color); /* Optionally change border color */
}

.radio-button-label input[type="radio"]:checked + .checkmark:before {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  background-color: var(--primary-color); /* Center dot color */
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.radio-description {
  margin-top: 4px;
  font-size: 12px; /* Adjust size as needed */
  color: gray; /* Adjust color as needed */
  padding-left: 28px; /* Align description with label text */
}
