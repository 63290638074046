/* font family */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

/* root sheet */
:root {
  --primary-color: #163393;
  --dark-blue-color: #1a4881;
  --dark-blue1: #081329;
  --button-color: #8cc90d;
  --secondary-color: #366adb;
  --accent-color: #e74c3c;
  --text-color: #131022;
  --border-color: #e5e7eb;
  --gray-color: #585c7b;
  --dark-green: #569501;
  --tw-shadow-color: #366adb;

  --font-family: "Manrope", sans-serif;
  --font-kanit: "Kanit", "sans-serif";
}

@keyframes elementor-animation-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

body {
  @apply font-Manrope;
}

:focus {
  outline: 0;
  box-shadow: none !important;
}

select:focus,
input:focus,
textarea:focus {
  border: 1px solid #dddddd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}
p {
  color: var(--gray-color);
}

.readmMoreBtn:hover {
  animation-name: elementor-animation-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

.icon-hover-fill {
  transition: fill 0.3s ease;
}

.icon-hover-fill:hover svg {
  fill: #f59e0b; /* Replace with the desired hover color */
}

/* .filtersSection ul li {
  display: block;
  margin-bottom: 15px;
} */

.filtersSection label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  width: 100%;
}

.filtersSection label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #d1d5db;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
}

.filtersSection input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.filtersSection input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  right: 7px;
  width: 6px;
  height: 10px;
  border: solid #081329;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.filtersSection input:checked + label:before {
  background-color: #e6b000;
}

.modalPopup::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 10px;
}

.modalPopup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: darkgrey;
  border-radius: 10px;
}

.modalPopup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #163393;
}

.filterContent::-webkit-scrollbar {
  background-color: #ffffff;
  width: 8px;
}

.filterContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.filterContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0400ff;
}

@media (min-width: 1024px) and (max-width: 1700px) {
  /* .direction-rtl {
    padding: 0 18rem;
  }

  .direction-ltr {
    padding: 0 18rem;
  } */
}
