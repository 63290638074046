.figure {
  position: absolute;
  width: 12.5%;
  height: 12.5%;
  padding: 6px;
  transition: all .5s;
  cursor: pointer;

  svg {
    transition: all .5s;
  }

  &:hover svg {
    transform: scale(1.06);
  }
}

.figureEatable {
  background-color: rgba(255, 0, 0, 0.2);
}